<template>
    <!-- 学会负责人    -->
    <div class="ye_people">
        <topHeader></topHeader>
        <div class="notice_cont">
            <PageContent :title="'学会负责人'" :total="total" :list="peopleList" @getArticleList="getPeoList"/>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import axios  from '../http/axios'
    import PageContent from '../comm/pageContent.vue'
    export default {
        data(){
            return{
                peopleList:[],
                total:null
            }
        },
        components:{
            PageContent
        },
        mounted(){
            this.getPeoList()
        },
        methods:{
            getPeoList(page=1,page_number=6){
                axios.get('getInsider',{params:{page,page_number}}).then(res=>{
                    // res.data.data.forEach(ele => {
                    //     // ele.achievement = ele.achievement.replace(/<p>/gi,'')
                    //     // ele.achievement = ele.achievement.replace(/<\/p>/gi,'')
                    // });
                    this.peopleList = res.data.data
                    this.total = res.data.total
                })
            }
        }
    }
</script>

<style scoped>

</style>